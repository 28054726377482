import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Row, Col, Button, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { addBill, getBills, getBill, getLastNumBill, editBill, removeBill, convertBill, sendBill } from '../../../actions/billsActions'
import { getMovementsToBillByBusiness, resetMovement } from '../../../actions/movementsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'
import { getBusinesses } from '../../../actions/businessesActions'
import BillForm from '../../../components/forms/BillForm'
import { PDFDownloadLink } from '@react-pdf/renderer'
import BillPDF from './BillPDF'
import AutoBillPDF from './AutoBillPDF'

export default function Bills({typeBill}) {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {bills, bill, resultbill, numbill,loading} = useSelector(state=>state.bills)
    const {movements} = useSelector(state=>state.movements)
    const {businesses} = useSelector(state=>state.businesses)

    const [modalShow, setModalShow] = useState(false)
    const [formState, setFormState] = useState({})
    const [combinedMovements, setCombinedMovements] = useState([])
    const [manualMovements, setManualMovements] = useState([])
    const [isRegistering, setIsRegistering] = useState(false)
    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false)

    const [base, setBase] = useState(0)
    const [iva, setIva] = useState(0)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        if(client){
            dispatch(getBills(typeBill))
            dispatch(getBusinesses(typeBill === 'autobill' ? 'provider' : 'client'))
            dispatch(getLastNumBill(new Date().getFullYear(), typeBill))
        }
    },[dispatch, t, client])

    useEffect(() => {
        // Ejecuta lógica cuando la URL cambia
        if(client){
            dispatch(getBills(typeBill))
            dispatch(getBusinesses(typeBill === 'autobill' ? 'provider' : 'client'))
            dispatch(getLastNumBill(new Date().getFullYear(), typeBill))
        }
    }, [location])

    const openModal = () =>{
        setIsAddingOrEditing('adding')
        setManualMovements([])
        setFormState({
            business: null,
            date: new Date(),
            numBill: numbill,
            tax: client?.tax,
            movements: []
        })
        dispatch(resetMovement())
        setModalShow(true)
    }

    const selectBill = (bill) => {
        dispatch(getBill(bill._id))
        setManualMovements([])
        setIsAddingOrEditing('editing')
        setModalShow(true)
    }

    useEffect(() => {
        if(formState?.date && isAddingOrEditing === 'adding'){
            dispatch(getLastNumBill(new Date(formState.date).getFullYear(), typeBill))
        }
    },[dispatch, formState?.date])

    useEffect(() => {
        if(numbill && isAddingOrEditing === 'adding'){
            setFormState({...formState, numBill: numbill})
        }
    },[numbill])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleSelectChange = (element, attribute)=>{
        if(attribute === 'business'){
            setFormState({
                ...formState,
                [attribute]: element.value
            })
            dispatch(getMovementsToBillByBusiness(element.value))
        }else{
            setFormState({
                ...formState,
                [element.target.name]: element.target.value
            })
        }
    }

    const handleCheckMovementChange = ({ target }, movement) => {
        if (target.checked) {
            setFormState((prevState) => ({
                ...prevState,
                movements: [
                    ...prevState.movements,
                    {
                        _id: movement._id,
                        date: movement.date,
                        description: movement.nature,
                        document: movement.document,
                        quantity: movement.quantity || 0,
                        price: 0
                    },
                ],
            }))
        } else {
            setFormState((prevState) => ({
                ...prevState,
                movements: prevState.movements.filter((m) => m._id !== movement._id),
            }));
        }
    };
    const handleEditMovementField = (movementId, field, value) => {
        setFormState((prevState) => ({
            ...prevState,
            movements: prevState.movements.map((movement) =>
                movement._id === movementId
                    ? { ...movement, [field]: value }
                    : movement
            ),
        }))
    }

    const handleManualMovementChange = (index, field, value) => {
        const updatedMovements = manualMovements.map((movement, i) =>
            i === index ? { ...movement, [field]: value } : movement
        )
        setManualMovements(updatedMovements)
    }

    const handleRemoveManualMovement = (index) => {
        setManualMovements(manualMovements.filter((_, i) => i !== index))
    }

    useEffect(() => {
        const allMovements = [...(formState?.movements || []), ...manualMovements]
        const newPrecioConIva = allMovements.reduce((sum, movement) => sum + Number(movement.price || 0), 0)

        const taxRate = Number(formState.tax || 0)
        const baseImponible = newPrecioConIva / (1 + taxRate / 100)
        const newIva = newPrecioConIva - baseImponible


        setBase(baseImponible.toFixed(2))
        setIva(newIva.toFixed(2))
        setTotal(newPrecioConIva.toFixed(2))
    }, [formState?.movements, manualMovements, formState.tax])




    useEffect(()=>{
        if (bill) {
            setFormState({
                id: bill._id,
                business: bill.business._id,
                date: bill.date,
                numBill: typeBill === 'proforma' ? bill.numProforma :bill.numBill,
                tax: bill.tax,
                isBill: bill.numBill ? 1: 0,
                numProforma: bill.numProforma,
                movements: bill.movements.map(movement => ({
                    _id: movement._id,
                    date: movement.date,
                    description: movement.description,
                    quantity: movement.quantity,
                    document: movement.document,
                    price: movement.price,
                }))
            })
        }
    }, [bill])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsRegistering(true);
    
        if (isAddingOrEditing === 'editing') {
            dispatch(editBill(formState, formState.id, typeBill));
        } else {
            // Combina movimientos antes de actualizar el estado
            const combinedMovements = [...formState.movements, ...manualMovements];

            // Usa los movimientos combinados directamente en el dispatch
            const formStateWithMovements = { ...formState, movements: combinedMovements };

            // Envía el formulario al backend
            dispatch(addBill(formStateWithMovements, typeBill));
    
            // Si es necesario, actualiza el estado de formState localmente
            setFormState(formStateWithMovements);
        }
    
        setIsRegistering(false);
    };


    const handleDeleteBills = async(e, billId) => {
        e.preventDefault()
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            icon: 'warning',
            html: t('admin.bills.areyousureremove')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeBill(billId))
                setModalShow(false)
            }
        })
    }

    const handleConvertBills = async(e, billId) => {
        e.preventDefault()
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            icon: 'warning',
            html: t('admin.bills.areyousureconvert')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(convertBill(billId))
                setModalShow(false)
            }
        })
    }

    const handleSendBill = async(e, billId) => {
        e.preventDefault()
        dispatch(sendBill(billId))
    }

    useEffect(() => {
        if(resultbill){
            let resultbillTemp = resultbill
            dispatch({ type: 'BILL_RESET_RESULT'})
            switch (resultbillTemp) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.bills.addsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break
                case 'successedit':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.bills.editsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break
                case 'successsend':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.bills.sendsuccess')
                    }).then(() => {
                        setModalShow(false)
                    })
                    break
                case 'deletesuccess':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.bills.deletesuccess')
                    }).then(() => {
                        setModalShow(false)
                    })
                    break
                default:
                    break
            }
        }
    // eslint-disable-next-line
    }, [resultbill])

    const columnsBills = [
        {
            name: t('admin.bills.date'),
            width: '180px',
            cell: bill =>
                <div className='cursor' onClick={() => selectBill(bill)}>
                    {new Date(bill.date).toLocaleDateString('es-ES', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        timeZone: 'Europe/Madrid'
                    })}
                </div>,
            selector: (bill) => bill.date,
            sortable: true,
        },{
            name: t('admin.bills.numBill'),
            width: '180px',
            cell: bill =>
                <div className='cursor' onClick={() => selectBill(bill)}>
                    {bill.type === 'proforma' && typeBill === 'proforma'
                    ?
                    bill.numProforma : typeBill === 'proforma' ? bill.numProforma : bill.numBill}
                </div>,
            selector: (bill) => bill.type === 'proforma' && typeBill === 'proforma' ? bill.numProforma : typeBill === 'proforma' ? bill.numProforma : bill.numBill,
            sortable: true,
        },{
            name: t('admin.bills.business'),
            left: true,
            cell: bill =>
                <div className='cursor' onClick={() => selectBill(bill)}>
                    {bill.business?.identifier} - {bill.business?.name}
                </div>,
            selector: (bill) => `${bill.business?.identifier} - ${bill.business?.name}`,
            sortable: true,
        },{
            name: `${t('admin.bills.total')} kg`,
            right: true,
            cell: bill =>{
                const total = bill.movements.reduce((suma, movimiento) => suma + movimiento.quantity, 0)
                return (
                    <div className='cursor' onClick={() => selectBill(bill)}>
                        {total}
                    </div>
                )
            },
            sortable: true,
        },{
            name: t('admin.bills.total'),
            right: true,
            cell: bill =>{
                const total = bill.movements.reduce((suma, movimiento) => suma + movimiento.price, 0)
                return (
                    <div className='cursor' onClick={() => selectBill(bill)}>
                        {total} €
                    </div>
                )
            },
            sortable: true,
        },{
            name: '',
            width: '180px',
            center: true,
            cell: bill =>
                <div className='d-flex justify-content-between'>
                    <div className='cursor mx-2' onClick={() => selectBill(bill)}>
                        <i className='fas fa-pencil'></i>
                    </div>
                    {typeBill !== 'proforma' || (typeBill === 'proforma' && bill.numBill === null)
                    ?
                        <div className='cursor mx-2' onClick={(e) => handleDeleteBills(e, bill._id)}>
                            <i className='fas fa-trash'></i>
                        </div>
                    :
                        <></>
                    }
                    {bill.type === 'autobill'
                    ?
                        <PDFDownloadLink document={<AutoBillPDF bill={bill}/>} fileName={`autofactura_${new Date(bill?.date).getFullYear()}_${bill?.numBill}.pdf`}>
                            <div className='cursor mx-2'>
                                <i className="fas fa-file-pdf"></i>
                            </div>
                        </PDFDownloadLink>
                    :
                        <PDFDownloadLink document={<BillPDF bill={bill} typeBill={typeBill}/>} fileName={`${typeBill === 'bill' ? 'factura' : 'proforma'}_${new Date(bill?.date).getFullYear()}_${typeBill === 'bill' ? bill?.numBill : bill?.numProforma}.pdf`}>
                            <div className='cursor mx-2'>
                                <i className="fas fa-file-pdf"></i>
                            </div>
                        </PDFDownloadLink>
                    }
                    {bill.business?.email &&
                        <div className='cursor mx-2' onClick={(e) => handleSendBill(e, bill._id)}>
                            <i className='fas fa-envelope'></i>
                        </div>
                    }
                </div>,
            sortable: false,
        }
    ]

    return (
        <Container className='mt-5 px-0'>
            <Row>
                <Col className='text-center'>
                    <h1>{t(`admin.bills.${typeBill}`)}</h1>
                </Col>
                <Col xs={12} className='d-flex justify-content-end'>
                    <Button variant='primary' type="submit" className="my-2 cursor" onClick={() => openModal()}>
                        {t('admin.bills.createbill')}
                    </Button>
                </Col>
            </Row>

            {
                loading
                    ?
                        <Loader/>
                    :

                        <Row>
                            <Col>
                            {bills?.length > 0
                                ?
                                    <CustomDataTable columns={columnsBills} data={bills} exportable={false} printable={false}/>
                                :
                                    <h4 className='mt-4 text-center mb-5'>{t('admin.bills.thereisnotbills')}</h4>
                            }
                            </Col>
                        </Row>

            }
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>{t('admin.bills.bills')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BillForm
                        state={formState}
                        manualMovements={manualMovements}
                        setManualMovements={setManualMovements}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        handleDeleteBills={handleDeleteBills}
                        handleCheckMovementChange={handleCheckMovementChange}
                        handleEditMovementField={handleEditMovementField}
                        handleManualMovementChange={handleManualMovementChange}
                        handleRemoveManualMovement={handleRemoveManualMovement}
                        handleConvertBills={handleConvertBills}
                        typeBill={typeBill}
                        businesses={businesses}
                        base={base}
                        iva={iva}
                        total={total}
                        submit={handleSubmit}
                        isAddingOrEditing={isAddingOrEditing}
                        isRegistering={isRegistering}
                        movements={isAddingOrEditing === 'editing' ? formState?.movements : movements}
                    />
                </Modal.Body>
            </Modal>
        </Container>
    )
}