import { Button, Col, Form, Row, Table} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Select from "react-select"

export default function BillForm({
        state, movements, handleInputChange, handleSelectChange, handleCheckMovementChange, handleEditMovementField,
        submit, isAddingOrEditing, isRegistering, handleDeleteBills,
        handleManualMovementChange, handleRemoveManualMovement, manualMovements,setManualMovements,
        handleConvertBills,businesses, typeBill, base, iva, total}) {

    const { t } = useTranslation()

    return (
        <Form  onSubmit={submit} className="px-4 formulario">
            <Row className="mb-3">
                <Col xs={6} md={4} className="ms-auto d-flex justify-content-end">
                {typeBill === 'proforma' && !state.isBill && isAddingOrEditing === 'editing'
                ?
                    <Button variant="fourth" size="sm" onClick={(e) => handleConvertBills(e, state?.id)}>
                        <i className="fas fa-plus"></i> {t('admin.bills.convertBill')}
                    </Button>
                :
                    <></>
                }
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="my-2">
                    <Form.Label>{t('admin.bills.business')}</Form.Label>
                    {isAddingOrEditing === 'adding'
                    ?
                        <Select
                            className="sd-input"
                            name='business'
                            value={state?.business ? {value: state?.business, label: businesses?.find(business => business._id === state?.business)?.name} : ''}
                            onChange={(e) => handleSelectChange(e, 'business')}
                            options={[
                                ...(businesses?.map(business => ({value: business._id, label: business.name})) || [])
                            ]}
                            required
                        />
                    :
                        <h4>{businesses?.find(business => business._id === state?.business)?.name}</h4>
                    }
                </Col>
                <Col xs={12} md={3} className="my-2">
                    <Form.Label>{t('admin.bills.numBill')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='number'
                        name='numBill'
                        value={state.numBill || ''}
                        onChange={handleInputChange}
                        required
                    />
                </Col>
                <Col xs={12} md={3} className="my-2">
                    <Form.Label>{t('admin.bills.date')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='date'
                        name='date'
                        value={state.date ? new Date(state.date).toISOString().split('T')[0] : ''}
                        onChange={handleInputChange}
                        required
                    />
                </Col>
            </Row>
            {isAddingOrEditing === 'adding' && typeBill !== 'autobill'
            ?
                <Row>
                    <Col xs={12} md={4} className="my-2 ms-auto text-end">
                        <div
                            className="cursor"
                            onClick={() =>
                                setManualMovements([
                                    ...manualMovements,
                                    { date: '', description: '', document: '', quantity: 0, price: 0, _id: `manual-${Date.now()}` }
                                ])
                            }
                        >
                            <i className="fas fa-plus"></i> {t('admin.bills.addManualMovement')}
                        </div>
                    </Col>
                </Row>
            :
                <></>
            }
            <Row>
                <Col xs={12} className="my-2">
                    {movements?.length >0 || manualMovements?.length > 0
                    ?
                    <>
                        <h4>{t('admin.bills.movements')}</h4>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    {isAddingOrEditing === 'adding' &&
                                        <>
                                            <th></th>
                                        </>
                                    }
                                    <th>{t('admin.movements.date')}</th>
                                    <th>{t('admin.movements.description')}</th>
                                    <th>
                                    {typeBill === 'autobill'
                                    ?
                                        t('admin.movements.document')
                                    :
                                        <>Euro/KG</>
                                    }
                                    </th>
                                    <th>{t('admin.movements.quantity')} (KG)</th>
                                    <th>{t('admin.movements.price')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {movements?.map((movement) => {
                                    const isSelected = isAddingOrEditing === 'editing' || state.movements.some((m) => m._id === movement._id)
                                    const currentMovement = state.movements.find((m) => m._id === movement._id) || {
                                        date: movement.date || '',
                                        description: movement.nature || movement.description || '',
                                        document: movement.document || '',
                                        quantity: movement.quantity || 0,
                                        price: 0
                                    }
                                    return (
                                        <tr key={movement._id}>
                                            {isAddingOrEditing === 'adding'
                                            ?
                                                <td style={{width: '5%'}} className="text-center align-middle">
                                                    <Form.Check
                                                        type="checkbox"
                                                        name={movement._id}
                                                        value={movement._id}
                                                        checked={isSelected}
                                                        onChange={(e) => handleCheckMovementChange(e, movement)}
                                                    />
                                                </td>
                                            :

                                                    <Form.Control
                                                        type="hidden"
                                                        name={movement._id}
                                                        value={movement._id}
                                                    />
                                                
                                            }
                                            <td style={{width: '5%'}} className="text-center align-middle">
                                                <Form.Control
                                                    type='date'
                                                    value={currentMovement.date ? new Date(currentMovement.date).toISOString().split('T')[0] : ''}
                                                    onChange={(e) =>
                                                        handleEditMovementField(movement._id, 'date', e.target.value)
                                                    }
                                                    disabled={!isSelected}
                                                />
                                            </td>
                                            <td style={{width: '40%'}}>
                                                <Form.Control
                                                    type="text"
                                                    value={currentMovement.description || ''}
                                                    onChange={(e) =>
                                                        handleEditMovementField(movement._id, 'description', e.target.value)
                                                    }
                                                    disabled={!isSelected}
                                                />
                                            </td>
                                            <td style={{width: '15%'}}>
                                                <Form.Control
                                                    type="text"
                                                    value={currentMovement.document || ''}
                                                    onChange={(e) =>
                                                        handleEditMovementField(movement._id, 'document', e.target.value)
                                                    }
                                                    disabled={!isSelected}
                                                />
                                            </td>
                                            <td style={{width: '15%'}}>
                                                <Form.Control
                                                    className="text-end"
                                                    type="number"
                                                    value={currentMovement.quantity || 0}
                                                    onChange={(e) =>
                                                        handleEditMovementField(movement._id, 'quantity', e.target.value)
                                                    }
                                                    disabled={!isSelected}
                                                />
                                            </td>
                                            <td style={{width: '15%'}}>
                                                <Form.Control
                                                    className="text-end"
                                                    type="number"
                                                    value={currentMovement.price || 0}
                                                    onChange={(e) =>
                                                        handleEditMovementField(movement._id, 'price', e.target.value)
                                                    }
                                                    disabled={!isSelected}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                                {manualMovements?.map((movement, index) => (
                                    <tr key={movement._id}>
                                        {isAddingOrEditing === 'adding'
                                            ?
                                            <td style={{width: '5%'}} className="text-center align-middle">
                                                <i className="fas fa-trash" onClick={() => handleRemoveManualMovement(index)}></i>
                                            </td>
                                            :
                                            <></>
                                        }
                                        <td style={{ width: '5%' }} className="text-center align-middle">
                                            <Form.Control
                                                type="date"
                                                value={movement.date || ''}
                                                onChange={(e) =>
                                                    handleManualMovementChange(index, 'date', e.target.value)
                                                }
                                            />
                                        </td>
                                        <td style={{ width: '40%' }}>
                                            <Form.Control
                                                type="text"
                                                value={movement.description || ''}
                                                onChange={(e) =>
                                                    handleManualMovementChange(index, 'description', e.target.value)
                                                }
                                            />
                                        </td>
                                        <td style={{ width: '15%' }}>
                                            <Form.Control
                                                type="text"
                                                value={movement.document || ''}
                                                onChange={(e) =>
                                                    handleManualMovementChange(index, 'document', e.target.value)
                                                }
                                            />
                                        </td>
                                        <td style={{ width: '15%' }}>
                                            <Form.Control
                                                className="text-end"
                                                type="number"
                                                value={movement.quantity || 0}
                                                onChange={(e) =>
                                                    handleManualMovementChange(index, 'quantity', e.target.value)
                                                }
                                            />
                                        </td>
                                        <td style={{ width: '15%' }}>
                                            <Form.Control
                                                className="text-end"
                                                type="number"
                                                value={movement.price || 0}
                                                onChange={(e) =>
                                                    handleManualMovementChange(index, 'price', e.target.value)
                                                }
                                            />
                                        </td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan={5} className="text-center align-middle">

                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={isAddingOrEditing === 'adding' ? 5 : 4} className="text-end">
                                        <strong>{t('admin.bills.base')}</strong>
                                    </td>
                                    <td className="text-end">
                                        <strong>{parseFloat(base).toFixed(2)}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={isAddingOrEditing === 'adding' ? 5 : 4} className="text-end">
                                        <strong>{t('admin.bills.tax')} ({state?.tax}%)</strong>
                                    </td>
                                    <td className="text-end">
                                        <strong>{parseFloat(iva).toFixed(2)}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={isAddingOrEditing === 'adding' ? 5 : 4} className="text-end">
                                        <strong>{t('admin.bills.total')}</strong>
                                    </td>
                                    <td className="text-end">
                                        <strong>{parseFloat(total).toFixed(2)}</strong>
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </>
                    :
                        <>{t('admin.bills.thereIsNotMovementsToBill')}</>
                    }

                </Col>
            </Row>
            <Row>
                <Col xs={12} md={5} className="my-2 ms-auto">
                    <Button variant='primary' type="submit" className="w-100 my-2">
                    {isRegistering
                        ?
                            <>
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </>
                        :
                            isAddingOrEditing === 'editing'
                            ?
                                <>{t('global.edit')}</>
                            :
                                <>{t('global.register')}</>
                    }
                    </Button>
                    {isAddingOrEditing === 'editing' && (
                        <Button variant="fourth" className="w-100 my-2 cursor" onClick={(e) => handleDeleteBills(e, state?.id)}>
                            {t('global.delete')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    )
}