import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Container, Row, Col, Button, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { addBusiness, getBusinesses, editBusiness, addDocument, deleteDocument, enableBusiness, disableBusiness } from '../../../actions/businessesActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'
import BusinessForm from '../../../components/forms/BusinessForm'
import BusinessDocumentsForm from '../../../components/forms/BusinessDocumentsForm'

export default function Businesses({typeBusiness = 'provider'}) {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {businesses, resultbusiness, loading} = useSelector(state=>state.businesses)

    const [modalShow, setModalShow] = useState(false)
    const [modalShowDocument, setModalShowDocument] = useState(false)
    const [formState, setFormState] = useState({})
    const [formStateDocument, setFormStateDocument] = useState({})
    const [isRegistering, setIsRegistering] = useState(false)
    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false)
    const [filterActive, setFilterActive] = useState(true)

    useEffect(() => {
        if(client){
            dispatch(getBusinesses(typeBusiness))
        }
    },[dispatch, t, client])

    useEffect(() => {
        // Ejecuta lógica cuando la URL cambia
        if(client){
            dispatch(getBusinesses(typeBusiness))
        }
    }, [location])

    const openModal = () =>{
        setIsAddingOrEditing('adding')
        setFormState({
            identifier: '',
            name: '',
            companyName: '',
            contact: '',
            address: '',
            cp: '',
            location: '',
            province: '',
            phone: '',
            cif: '',
            iscc: false,
            others: '',
        })
        setModalShow(true)
    }

    const selectBusiness = (business) => {
        setIsAddingOrEditing('editing')
        setFormState(business)
        setModalShow(true)
    }
    const selectBusinessDocument = (business) => {
        setFormStateDocument({
            businessId: business._id,
            businessName: business.name,
            documentDescription: '',
            documentUrl: '',
            documents:business.documents
        })
        setModalShowDocument(true)
    }

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }
    const handleInputDocumentChange = ({target})=>{
        setFormStateDocument({
            ...formStateDocument,
            [target.name]: target.value
        })
    }
    const handleFileChange = ({target})=>{
        setFormStateDocument({
            ...formStateDocument,
            [target.name]: target.files[0]
        })
    }

    const handleCheckboxChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.checked
        })
    }

    const handleDeleteDocument = async(document, business) => {

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.yes'),
            denyButtonText: t('global.no'),
            icon: 'question',
            text:  t('admin.businesses.areyousuredeletedocument')
        }).then((resultuser) => {
            if (resultuser.isConfirmed) {
                dispatch(deleteDocument(document, business))
            }
        })
    }

    const handleSubmit= async(e) => {
        e.preventDefault()
        setIsRegistering(true)

        if(isAddingOrEditing==='editing'){
            dispatch(editBusiness(formState, formState._id))
        }else{
            dispatch(addBusiness(formState,typeBusiness))
        }
        setIsRegistering(false)
    }

    const handleSubmitDocument= async(e) => {
        e.preventDefault()
        setIsRegistering(true)
        dispatch(addDocument(formStateDocument, formStateDocument.businessId))
        setIsRegistering(false)
    }

    const disableEnableBusinesses = async(e) => {
        e.preventDefault()
        let textQuestion = ''
        formState.active
            ?
                textQuestion = t('admin.businesses.areyousuredisable')+'<br><p><label className="fs-20 fw-600 pt-5">'+formState?.name+'</label></p>'
            :
                textQuestion = t('admin.businesses.areyousureenable')+'<br><p><label className="fs-20 fw-600 pt-5">'+formState?.name+'</label></p>'

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            html: textQuestion
        }).then((result) => {
            if (result.isConfirmed) {
                if (formState.active) {
                    dispatch(disableBusiness(formState._id))
                }else{
                    dispatch(enableBusiness(formState._id))
                }
            }
        })
    }

    useEffect(() => {
        if(resultbusiness){
            let resultbusinessTemp = resultbusiness
            dispatch({ type: 'PROVIDER_RESET_RESULT'})
            switch (resultbusinessTemp) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.businesses.addsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                case 'successedit':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.businesses.editsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setModalShowDocument(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                case 'successDeleteDocument':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.businesses.deletesuccess')
                    }).then(() => {
                        setModalShowDocument(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                case 'successenable':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.businesses.enablesuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                case 'successdisable':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.businesses.disablesuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultbusiness])

    const columnsBusinesses = [
        {
            name: t('admin.businesses.identifier'),
            cell: business =>
                <div className='cursor' onClick={() => selectBusiness(business)}>
                    {business.identifier}
                </div>,
            selector: (business) => business.identifier,
            sortable: true,
        },{
            name: t('admin.businesses.name'),
            width: '30%',
            cell: business =>
                <div className='cursor' onClick={() => selectBusiness(business)}>
                    {business.name}
                </div>,
            selector: (business) => business.name,
            sortable: true,
        },{
            name: t('admin.businesses.location'),
            width: '20%',
            cell: business =>
                <div className='cursor' onClick={() => selectBusiness(business)}>
                    {business.location} {business.province ? <>({business.province})</> : ''}
                </div>,
            selector: (business) => business.location,
            sortable: true,
        },{
            name: t('admin.businesses.phone'),
            width: '10%',
            cell: business =>
                <div className='cursor' onClick={() => selectBusiness(business)}>
                    {business.phone}
                </div>,
            selector: (business) => business.phone,
            sortable: true,
        },{
            name: t('admin.businesses.iscc'),
            width: '100px',
            cell: business =>
                <div>
                    {business.iscc
                        ?
                            <img src="../images/check.svg" alt="check" width={25}></img>
                        :
                            <img src="../images/times.svg" alt="times" width={25}></img>
                    }
                </div>,
            selector: (business) => business.iscc,
            sortable: true,
        },{
            name: t('admin.businesses.active'),
            width: '80px',
            cell: business =>
                <div>
                    {business.active
                        ?
                            <img src="../images/check.svg" alt="check" width={25}></img>
                        :
                            <img src="../images/times.svg" alt="times" width={25}></img>
                    }
                </div>,
            selector: (business) => business.active,
            sortable: true,
        },{
            name: '',
            width: '100px',
            center: true,
            cell: business =>
                <>
                    <div className='cursor me-3' onClick={() => selectBusiness(business)}>
                        <i className='fas fa-pencil'></i>
                    </div>
                    <div className='cursor' onClick={() => selectBusinessDocument(business)}>
                        <i className='fas fa-paperclip'></i> {business.documents?.length > 0 ? business.documents.length : ''}
                    </div>
                </>,
            sortable: false,
        }
    ]
    const ExpandedRow = ({ data }) => {
        return (
            <Container className='p-3'>
                <Row>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.businesses.cif')}</small> {data?.cif}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.businesses.companyName')}</small> {data?.companyName}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.businesses.email')}</small> {data?.email}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.businesses.phone')}</small> {data?.phone}
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.businesses.contact')}</small> {data?.contact}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.businesses.address')}</small> {data?.address}
                        </div>
                        <div className='d-flex justify-content-between border-1 border-bottom p-2'>
                            <small>{t('admin.businesses.cp')}</small> {data?.cp}
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    const filteredBusinesses = businesses?.filter(business => business.active === filterActive)

    return (
        <Container className='mt-5 px-0'>
            <Row>
                <Col xs={12} className='text-center'>
                    <h1>
                        {t(`admin.businesses.${typeBusiness}`)}&nbsp;
                        {filterActive
                            ?
                            t('admin.businesses.businessesenabled')
                            :
                            t('admin.businesses.businessesdisabled')
                        }
                    </h1>
                </Col>
                <Col xs={6} className='d-flex justify-content-start'>
                    <ToggleButtonGroup
                        type="radio"
                        name="filterActive"
                        value={filterActive}
                        onChange={setFilterActive}
                    >
                        <ToggleButton id="tbg-active" value={true} variant="outline-success">
                            {t('admin.businesses.enabled')}
                        </ToggleButton>
                        <ToggleButton id="tbg-inactive" value={false} variant="outline-danger">
                            {t('admin.businesses.disabled')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Col>
                <Col xs={6} className='d-flex justify-content-end'>
                    <Button variant='primary' type="submit" className="my-2 cursor" onClick={() => openModal()}>
                        {t('admin.businesses.createbusiness')}
                    </Button>
                </Col>
            </Row>
            {
                loading
                    ?
                        <Loader/>
                    :

                        <Row>
                            <Col>
                            {filteredBusinesses?.length > 0
                                ?
                                    <CustomDataTable
                                        columns={columnsBusinesses}
                                        data={filteredBusinesses}
                                        exportable={false}
                                        printable={false}
                                        expandedData={ExpandedRow}
                                    />
                                :
                                    <h4 className='mt-4'>{t('admin.businesses.thereisnotbusinesses')}</h4>
                            }
                            </Col>
                        </Row>

            }
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>{t(`admin.businesses.${typeBusiness}`)}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BusinessForm
                        state={formState}
                        handleInputChange={handleInputChange}
                        handleCheckboxChange={handleCheckboxChange}
                        submit={handleSubmit}
                        disableEnableBusinesses={disableEnableBusinesses}
                        isAddingOrEditing={isAddingOrEditing}
                        isRegistering={isRegistering}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={modalShowDocument}
                onHide={() => setModalShowDocument(false)}
                aria-labelledby="contained-modal-title-vcenter-clip"
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter-clip">
                        <h2>{formStateDocument?.businessName} - {t('admin.businesses.documents')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BusinessDocumentsForm
                        state={formStateDocument}
                        handleInputChange={handleInputDocumentChange}
                        handleFileChange={handleFileChange}
                        handleDeleteDocument={handleDeleteDocument}
                        submit={handleSubmitDocument}
                        isRegistering={isRegistering}
                    />
                </Modal.Body>
            </Modal>
        </Container>
    )
}