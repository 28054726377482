import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Table, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { getBalance } from '../../../actions/balancesActions'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { use } from 'i18next'

export default function Balance () {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const [dateSelected, setDateSelected] = useState([null, null])
    const {balance, initialBalance, loading} = useSelector(state=>state.balances)

    useEffect(() => {
        if(client){
            dispatch(getBalance())
        }
    },[dispatch, t, client])

    const handlePeriodChange = (value) => {
        setDateSelected([value ? value[0] : null, value ? value[1] : null])

    }

    useEffect(() => {
        dispatch(getBalance(dateSelected[0], dateSelected[1]))
    }, [dateSelected])

    const kgTon = (kg) => {
        return (kg/1000).toFixed(2)
    }

    return (
        <Container className='mt-5 px-0'>
            <Row>
                <Col xs={12} className='text-center'>
                    <h1>{t('admin.movements.balance')}</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className='my-2 ms-auto'>
                    <Form.Group controlId="selectDate">
                        <Form.Label>{t('admin.movements.selectDate')}</Form.Label>
                        <DateRangePicker
                            className="has-content form-control"
                            onChange={(value) => {handlePeriodChange(value)}}
                            value={dateSelected ? [dateSelected[0], dateSelected[1]] : [null, null]}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className='text-end'>
                    <h2>{t('admin.movements.initialBalance')}: {kgTon(initialBalance)} {t("global.toneladasbruto")}</h2>
                </Col>
            </Row>
            <Row>
                {(() => {
                    let accumulatedBalance = initialBalance
                    return balance?.map((monthData, index) => {
                        const monthBalance = monthData.totalEntries - monthData.totalOutputs
                        accumulatedBalance += monthBalance
                        return (
                        <Col xs={12} key={index}>
                            <Card className='my-3'>
                                <Card.Header>
                                    <h2>{t("months."+monthData.month)} {monthData.year}</h2>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} md={2}>
                                            <h3>{t("admin.movements.entries")}</h3>
                                            <h4>{kgTon(monthData.totalEntries)} {t("global.toneladasbruto")}</h4>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <h3>{t("admin.movements.outputs")}</h3>
                                            <h4>{kgTon(monthData.totalOutputs)} {t("global.toneladasbruto")}</h4>
                                        </Col>
                                        <Col xs={12} md={5}>
                                            <h3>{t("admin.movements.wastes")}</h3>
                                            <h4>{kgTon(monthData.totalWastes)} {t("global.toneladasbruto")}</h4>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={2}>
                                        </Col>
                                        <Col xs={12} md={5}>
                                        {monthData.outputs.length > 0 ? (
                                            <Table className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <td>{t("admin.movements.date")}</td>
                                                        <td>{t("admin.movements.nature")}</td>
                                                        <td>{t("admin.movements.business")}</td>
                                                        <td>{t("global.toneladasbruto")}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {monthData.outputs.map((output) => (
                                                    <tr key={output._id}>
                                                        <td>{new Date(output.date).toLocaleDateString()}</td>
                                                        <td>{output.nature}</td>
                                                        <td>{output.business}</td>
                                                        <td className='text-end'>{kgTon(output.quantity)}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        ) : (
                                            <p>{t("admin.movements.thereisnotmovements")}.</p>
                                        )}
                                        </Col>
                                        <Col xs={12} md={5}>
                                        {monthData.wastes.length > 0 ? (
                                            <Table className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <td>{t("admin.movements.date")}</td>
                                                        <td>{t("admin.movements.nature")}</td>
                                                        <td>{t("admin.movements.business")}</td>
                                                        <td>{t("global.toneladasbruto")}</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {monthData.wastes.map((waste) => (
                                                    <tr key={waste._id}>
                                                        <td>{new Date(waste.date).toLocaleDateString()}</td>
                                                        <td>{waste.nature}</td>
                                                        <td>{waste.business}</td>
                                                        <td className='text-end'>{kgTon(waste.quantity)}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        ) : (
                                            <p>{t("admin.movements.thereisnotmovements")}.</p>
                                        )}
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col className='text-end'>
                                            {t("admin.movements.balanceMonth")} {kgTon(monthBalance)} {t("global.toneladasbruto")}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-end'>
                                            <strong>{t("admin.movements.accumulated")} {kgTon(accumulatedBalance)} {t("global.toneladasbruto")}</strong>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                        )
                    })
                })()}
            </Row>
        </Container>
    )
}