import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { addMovement, getMovements,getMovement, editMovement, removeMovement } from '../../../actions/movementsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'
import { getBusinesses } from '../../../actions/businessesActions'
import MovementForm from '../../../components/forms/MovementForm'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

export default function Movements() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {movements, initialBalance, movement, resultmovement, loading} = useSelector(state=>state.movements)
    const {businesses} = useSelector(state=>state.businesses)

    const [modalShow, setModalShow] = useState(false)
    const [formState, setFormState] = useState({})
    const [isRegistering, setIsRegistering] = useState(false)
    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false)

    const [dateSelected, setDateSelected] = useState([null, null])
    const [movementsWithBalance, setMovementsWithBalance] = useState([])

    useEffect(() => {
        if(client){
            dispatch(getMovements())
            dispatch(getBusinesses())
        }
    },[dispatch, t, client])

    const openModal = () =>{
        setIsAddingOrEditing('adding')
        setFormState({
            business: null,
            date: new Date(),
            nature: 'UCO SOSTENIBLE',
            quantity: 0,
            typeMove: 'in'
        })
        setModalShow(true)
    }

    const selectMovement = (movement) => {
        dispatch(getMovement(movement._id))
        setIsAddingOrEditing('editing')
        setFormState(movement)
        setModalShow(true)
    }

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handleSelectChange = (element, attribute)=>{
        if(attribute === 'business'){
            setFormState({
                ...formState,
                [attribute]: element.value
            })
        }else{
            setFormState({
                ...formState,
                [element.target.name]: element.target.value
            })
        }
    }

    const handlePeriodChange = (value) => {
        setDateSelected([value ? value[0] : null, value ? value[1] : null])
    }

    useEffect(() => {
        dispatch(getMovements(dateSelected[0], dateSelected[1]))
    }, [dateSelected])

    useEffect(()=>{
        if (movement) {
            setFormState({
                ...movement,
                business: movement.business._id
            })
        }
    }, [movement, client, dispatch])

    const handleSubmit = async(e) => {
        e.preventDefault()
        setIsRegistering(true)
        if(isAddingOrEditing==='editing'){
            dispatch(editMovement(formState, formState._id))
        }else{
            dispatch(addMovement(formState))
        }
        setIsRegistering(false)
    }

    const handleDeleteMovements = async(e, movementId) => {
        e.preventDefault()
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            html: t('admin.movements.areyousureremove')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(removeMovement(movementId))
                setModalShow(false)
            }
        })
    }

    useEffect(() => {
        if(resultmovement){
            let resultmovementTemp = resultmovement
            dispatch({ type: 'MOVEMENT_RESET_RESULT'})
            switch (resultmovementTemp) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.movements.addsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setIsAddingOrEditing(false)
                    })
                    break
                default:
                    break
            }
        }
    // eslint-disable-next-line
    }, [resultmovement])

    const calculateRunningBalance = (movements, initial) => {
        let balance = initialBalance;
        const movementsWithBalance = [...movements]?.reverse().map(movement => {
            if(movement.typeMove === 'out'){
                 balance -= movement.quantity
            }else if(movement.typeMove === 'in'){
                balance += movement.quantity
            }
            return { ...movement, runningBalance: balance }
        })
        return movementsWithBalance.reverse()
    }
    useEffect(() => {
        if(movements){
            setMovementsWithBalance(calculateRunningBalance(movements, initialBalance))
        }
    }, [movements, dispatch])

    const columnsMovements = [
        {
            name: t('admin.movements.date'),
            width: '100px',
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {new Date(movement.date).toLocaleDateString()}
                </div>,
            selector: (movement) => movement.date,
            sortable: true,
        },{
            name: t('admin.movements.billed'),
            width: '100px',
            center: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.isBilled ? <i className='fas fa-check'></i> : ''}
                </div>,
            selector: (movement) => movement.isBilled,
            sortable: true,
        },{
            name: t('admin.movements.identifier'),
            width: '100px',
            left: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.business?.identifier}
                </div>,
            selector: (movement) => `${movement.business?.identifier}`,
            sortable: true,
        },{
            name: t('admin.movements.business'),
            left: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.business?.name}
                </div>,
            selector: (movement) => `${movement.business?.name}`,
            sortable: true,
        },{
            name: t('admin.movements.document'),
            width: '120px',
            center: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.document}
                </div>,
            selector: (movement) => movement.document,
            sortable: true,
        },{
            name: t('admin.movements.quantity'),
            width: '120px',
            right: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.typeMove === 'waste'
                    ? <span className='text-danger'>{movement.quantity} KG</span>
                    :
                        movement.typeMove === 'in'
                        ? <>+ {movement.quantity} KG</>
                        : <>- {movement.quantity} KG</>
                    }
                </div>,
            selector: (movement) => movement.quantity,
            sortable: true,
        },{
            name: 'Saldo arrastrado',
            width: '150px',
            right: true,
            cell: movement =>
                <div>
                    {movement.runningBalance} KG
                </div>,
            selector: (movement) => movement.runningBalance,
            sortable: true,
        },{
            name: t('admin.movements.nature'),
            width: '150px',
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {movement.nature}
                </div>,
            selector: (movement) => movement.nature,
            sortable: true,
        },{
            name: t('admin.movements.typeMove'),
            width: '100px',
            center: true,
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    {t('admin.movements.typeMove'+movement.typeMove)}
                </div>,
            selector: (movement) => movement.typeMove,
            sortable: true,
        },{
            name: '',
            width: '80px',
            cell: movement =>
                <div className='cursor' onClick={() => selectMovement(movement)}>
                    <i className='fas fa-pencil'></i>
                </div>,
            sortable: false,
        }
    ]

    return (
        <Container className='mt-5 px-0'>
            <Row>
                <Col className='text-center'>
                    <h1>{t('admin.movements.movements')}</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className='my-2 ms-auto'>
                    <Form.Group controlId="selectDate">
                        <Form.Label>{t('admin.movements.selectDate')}</Form.Label>
                        <DateRangePicker
                            className="has-content form-control"
                            onChange={(value) => {handlePeriodChange(value)}}
                            value={dateSelected ? [dateSelected[0], dateSelected[1]] : [null, null]}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={12} className='d-flex justify-content-end'>
                    <Button variant='primary' type="submit" className="my-2 cursor" onClick={() => openModal()}>
                        {t('admin.movements.createmovement')}
                    </Button>
                </Col>
            </Row>

            {
                loading
                    ?
                        <Loader/>
                    :
                        <Row>
                            <Col xs={12} className='text-end'>
                                {t('admin.movements.initialBalance')}: {initialBalance} KG
                            </Col>
                            <Col xs={12}>
                            {movements?.length > 0
                                ?
                                    <CustomDataTable
                                        columns={columnsMovements}
                                        data={movementsWithBalance}
                                        exportable={true}
                                        printable={false}
                                    />
                                :
                                    <h4 className='mt-4'>{t('admin.movements.thereisnotmovements')}</h4>
                            }
                            </Col>
                        </Row>

            }
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>{t('admin.movements.movements')}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MovementForm
                        state={formState}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        handleDeleteMovements={handleDeleteMovements}
                        businesses={businesses}
                        submit={handleSubmit}
                        isAddingOrEditing={isAddingOrEditing}
                        isRegistering={isRegistering}
                    />
                </Modal.Body>
            </Modal>
        </Container>
    )
}