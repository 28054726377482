import React from 'react'
import {Navbar, Nav, NavDropdown, Container} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { Link } from 'react-router-dom'

export default function MenuAdmin() {
	const { t } = useTranslation()
	const {logout} = useAuth()

  	return (
		<div className='cabecera d-flex justify-content-center align-items-center px-4'>
			<Navbar id="menu-admin" expand="lg" className='me-auto'>
				<Container>
					<Navbar.Brand href="#home">
						<img
							src="/images/logo-reagra.png"
							className="d-inline-block align-top justify-center logo"
							alt="Logotipo Córdoba Sin Gluten"
							width={100}
							id="logo-menu"
						/>
						<h3 className='text-white mb-1'>Reagra Córdoba</h3>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav" className='mt-auto'>
						<Nav className="me-auto ps-3">
							<Nav.Link as={Link} to="/admin/dashboard">{t('admin.dashboard.index')}</Nav.Link>
							<Nav.Link as={Link} to="/admin/movements">{t('admin.dashboard.movements')}</Nav.Link>
							<Nav.Link as={Link} to="/admin/balance">{t('admin.dashboard.balance')}</Nav.Link>
							<NavDropdown title={t('admin.dashboard.businesses')} id="basic-nav-dropdown">
								<NavDropdown.Item as={Link} to="/admin/providers">{t('admin.dashboard.providers')}</NavDropdown.Item>
								<NavDropdown.Item as={Link} to="/admin/clients">{t('admin.dashboard.clients')}</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title={t('admin.dashboard.billing')} id="basic-nav-dropdown">
								<NavDropdown.Item as={Link} to="/admin/autobills">{t('admin.dashboard.autobills')}</NavDropdown.Item>
								<NavDropdown.Item as={Link} to="/admin/bills">{t('admin.dashboard.bills')}</NavDropdown.Item>
								<NavDropdown.Item as={Link} to="/admin/proformas">{t('admin.dashboard.proformas')}</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link as={Link} to="/admin/logs">{t('admin.dashboard.logs')}</Nav.Link>
							<Nav.Link as={Link} to="/" onClick={logout}>{t('signin.logout')}</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
        </div>
		
	)
}