import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getMovements = (startDate, endDate) => async (dispatch) => {
    dispatch({
        type: 'MOVEMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/movement/getall/', {
            headers: { auth: localStorage.getItem('jwt') },
            params: {
                startDate: startDate ? new Date(startDate).toISOString().split('T')[0] : undefined,
                endDate: endDate ? new Date(endDate).toISOString().split('T')[0] : undefined,
            }
        })
        if (status === 200) {
            dispatch({
                type: 'MOVEMENT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MOVEMENT_NOT_LOADING'
        })
    }
}

export const getMovementsToBillByBusiness = (business) => async (dispatch) => {
    dispatch({
        type: 'MOVEMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/movement/getmovementstobill/'+business, {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'MOVEMENT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MOVEMENT_NOT_LOADING'
        })
    }
}

export const getMovement = (movement) => async (dispatch) => {
    dispatch({
        type: 'MOVEMENT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/movement/get/'+ movement, {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'MOVEMENT_GET_SUCCESS',
                payload: data.movement
            })
        }
    } catch (error) {
        dispatch({
            type: 'MOVEMENT_NOT_LOADING'
        })
    }
}

export const addMovement = (movementdata) => async (dispatch) => {
    dispatch({
        type: 'MOVEMENT_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/movement/register', movementdata, {
                headers: { auth: localStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'MOVEMENT_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MOVEMENT_NOT_LOADING'
        })
    }
}

export const editMovement = (movementdata, idMovement) => async (dispatch) => {
    try {
        const { data, status} = await Axios.put('/api/movement/edit/' + idMovement, movementdata, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'MOVEMENT_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MOVEMENT_ERROR'
        })
    }
}

export const removeMovement = (movement) => async (dispatch) => {
    try {
        const { data, status } = await Axios.delete('/api/movement/remove/' + movement, {
            headers: {
                auth: localStorage.getItem('jwt')
            }
        })
        if (status === 200 || status === 263) {
            dispatch({
                type: 'MOVEMENT_REMOVE_RESULT',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'MOVEMENT_NOT_LOADING'
        })
    }
}

export const resetMovement = () => async (dispatch) => {
    dispatch({
        type: 'MOVEMENTS_RESET'
    })
}