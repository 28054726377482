export const movementsReducer = (state = {loading: true, resultmovement: null, movements: null, initialBalance: null, movement: null } , action) => {
    switch (action.type) {
        case 'MOVEMENT_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                movements: action.payload.movements,
                initialBalance: action.payload.initialBalance
            }
        case 'MOVEMENT_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                movement: action.payload
            }
        case 'MOVEMENT_ADD_SUCCESS':
            return {
                ...state,
                loading:false,
                movements: [...state.movements, action.payload.movement],
                resultmovement: action.payload.message
            }
        case 'MOVEMENT_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                movements: state.movements.map(movement => movement._id === action.payload.movement._id ? action.payload.movement : movement),
                resultmovement: action.payload.message
            }
        case 'MOVEMENT_REMOVE_RESULT':
            return {
                ...state,
                loading:false,
                resultmovement: action.payload.message,
                movements: state.movements.filter(movements => movements._id !== action.payload.movement)
            }
        case 'MOVEMENT_RESET_RESULT':
            return {...state,
                resultmovement: null
            }
        case 'MOVEMENT_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'MOVEMENT_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        case 'MOVEMENTS_RESET':
            return {
                ...state,
                movements: null                
        }
        default:
            return state
    }
}

