import React, { useRef } from 'react'
import { Button, Col, Form, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { CustomDataTable } from '../../components/design/Datatables/CustomDataTable'

export default function BusinessDocumentsForm({state, handleInputChange, handleFileChange, handleDeleteDocument,submit, isRegistering}) {
    const { t } = useTranslation()
    const documentInput = useRef(null)

    const columnsDocuments = [
        {
            name: t('admin.businesses.documentDescription'),
            cell: document =>
                <div className='cursor'>
                    {document.description}
                </div>,
            selector: (document) => document.description,
            sortable: true,
        },{
            name: '',
            width: '100px',
            center: true,
            cell: document =>
                <>
                    <div className='cursor me-3' onClick={() => window.open(document.documentUrl, '_blank')}>
                        <i className='fas fa-cloud-arrow-down'></i>
                    </div>
                    <div className='cursor' onClick={() => handleDeleteDocument(document, state?.businessId)}>
                        <i className='fas fa-trash'></i>
                    </div>
                </>,
            sortable: false,
        }
    ]
    return (
        <Form onSubmit={submit} className="px-4 formulario">
            <Row>
                <Col xs={12} className="my-2">
                    <Form.Label>{t('admin.businesses.documentDescription')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='documentDescription'
                        value={state.documentDescription ||  ''}
                        onChange={handleInputChange}
                        required
                    />
                </Col>
                <Col xs={12}>
                    <Form.Label>{t('admin.businesses.documentFile')}</Form.Label>
                    <Form.Control
                        type='file'
                        name='documentUrl'
                        onChange={handleFileChange}
                        ref={documentInput}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className="my-2 ms-auto">
                    <Button variant='primary' type="submit" className="w-100 my-2">
                    {isRegistering
                        ?
                            <>
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </>
                        :
                            <>{t('global.register')}</>
                    }
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    {state?.documents?.length > 0
                        ?
                            <CustomDataTable
                                columns={columnsDocuments}
                                data={state?.documents}
                                exportable={false}
                                printable={false}
                            />
                        :
                            <h4 className='mt-4'>{t('admin.businesses.thereisnotdocuments')}</h4>
                    }
                </Col>
            </Row>
        </Form>
    )
}