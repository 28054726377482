import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getBusinesses = (typeBusiness = 'provider') => async (dispatch) => {
    dispatch({
        type: 'PROVIDER_LOADING'
    })    
    try {
        const { data, status } = await Axios.get('/api/business/getall/', {
            headers: { auth: localStorage.getItem('jwt') },
            params: {typeBusiness}
        })
        if (status === 200) {
            dispatch({
                type: 'PROVIDER_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_NOT_LOADING'
        })
    }
}

export const getBusiness = (business) => async (dispatch) => {
    dispatch({
        type: 'PROVIDER_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/business/get/'+ business, {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'PROVIDER_GET_SUCCESS',
                payload: data.business
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_NOT_LOADING'
        })
    }
}

export const addBusiness = (businessdata,typeBusiness) => async (dispatch) => {
    dispatch({
        type: 'PROVIDER_LOADING'
    })
    try {
        const { data, status} = await Axios.post('/api/business/register', businessdata, {
                headers: { auth: localStorage.getItem('jwt') },
                params: {typeBusiness}
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'PROVIDER_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_NOT_LOADING'
        })
    }
}

export const editBusiness = (businessdata, idBusiness) => async (dispatch) => {
    try {
        const { data, status} = await Axios.put('/api/business/edit/' + idBusiness, businessdata, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'PROVIDER_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_ERROR'
        })
    }
}

export const addDocument = (businessdata,idBusiness) => async (dispatch) => {
    try {
        let documentUrl = ''

        if(businessdata.documentUrl !== null && businessdata.documentUrl !== undefined && businessdata.documentUrl !== '' && typeof businessdata.documentUrl !== 'string'){
            let fileData = new FormData()
            fileData.append('file', businessdata.documentUrl)
            const {data} = await Axios.post('/api/upload/uploaddocument', fileData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: localStorage.getItem('jwt')
                }
            })
            documentUrl = data
        }else{
            documentUrl = businessdata.documentUrl
        }
        if(documentUrl){
            const { data, status} = await Axios.put('/api/business/registerdocument/' + idBusiness,
                    {
                        documentDescription: businessdata.documentDescription,
                        documentUrl: documentUrl
                    },
                    {   headers: { auth: localStorage.getItem('jwt') }
                }
            )
            if (status === 200 || status === 264) {
                dispatch({
                    type: 'DOCUMENT_ADD_SUCCESS',
                    payload: data
                })
            }
        }else{
            dispatch({
                type: 'DOCUMENT_ADD_ERROR'
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_NOT_LOADING'
        })
    }
}

export const deleteDocument = (document, idBusiness) => async (dispatch) => {    
    try {
        const { data, status } = await Axios.put('/api/business/removedocument/'+ idBusiness,
             {document},{ headers: { auth: localStorage.getItem('jwt') }}
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'DOCUMENT_ADD_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_NOT_LOADING'
        })
    }
}

export const enableBusiness = (idBusiness) => async (dispatch) => {
    try {
        const { data, status} = await Axios.put('/api/business/enable/' + idBusiness, {}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'PROVIDER_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_ERROR'
        })
    }
}

export const disableBusiness = (idBusiness) => async (dispatch) => {
    try {
        const { data, status} = await Axios.put('/api/business/disable/' + idBusiness, {}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 263 || status === 260) {
            dispatch({
                type: 'PROVIDER_EDIT_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'PROVIDER_ERROR'
        })
    }
}