import { Button, Col, Form, Row} from "react-bootstrap"
import { useTranslation } from "react-i18next"

export default function BusinessForm({state, handleInputChange, handleCheckboxChange, submit, isAddingOrEditing, isRegistering, disableEnableBusinesses}) {
    const { t } = useTranslation()

    return (
        <Form onSubmit={submit} className="px-4 formulario">
            <Row>
                <Col xs={12} md={4} className="my-2">
                    <Form.Label>{t('admin.businesses.identifier')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='identifier'
                        value={state.identifier ||  ''}
                        onChange={handleInputChange}
                        required
                    />
                </Col>
                <Col xs={12} md={8} className="my-2">
                    <Form.Label>{t('admin.businesses.name')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='name'
                        value={state.name ||  ''}
                        onChange={handleInputChange}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={8} className="my-2">
                    <Form.Label>{t('admin.businesses.companyName')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='companyName'
                        value={state.companyName ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col xs={12} md={4} className="my-2">
                    <Form.Label>{t('admin.businesses.cif')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='cif'
                        value={state.cif ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={5} className="my-2">
                    <Form.Label>{t('admin.businesses.contact')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='contact'
                        value={state.contact ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col xs={12} md={7} className="my-2">
                    <Form.Label>{t('admin.businesses.address')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='address'
                        value={state.address ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={2} className="my-2">
                    <Form.Label>{t('admin.businesses.cp')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='cp'
                        value={state.cp ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col xs={12} md={5} className="my-2">
                    <Form.Label>{t('admin.businesses.location')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='location'
                        value={state.location ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col xs={12} md={5} className="my-2">
                    <Form.Label>{t('admin.businesses.province')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='province'
                        value={state.province ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4} className="my-2">
                    <Form.Label>{t('admin.businesses.phone')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='phone'
                        value={state.phone ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
                <Col xs={12} md={8} className="my-2">
                    <Form.Label>{t('admin.businesses.email')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='email'
                        value={state.email ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="my-2">
                    <Form.Label>{t('admin.businesses.iscc')}</Form.Label>
                    <Form.Check
                        className="sd-input"
                        type='checkbox'
                        name='iscc'
                        checked={state.iscc || false}
                        onChange={(e) => handleCheckboxChange(e)}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="my-2">
                    <Form.Label>{t('admin.businesses.others')}</Form.Label>
                    <Form.Control
                        className="sd-input"
                        type='text'
                        name='others'
                        value={state.others ||  ''}
                        onChange={handleInputChange}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={6} className="my-2 ms-auto">
                    <Button variant='primary' type="submit" className="w-100 my-2">
                    {isRegistering
                        ?
                            <>
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </>
                        :
                            isAddingOrEditing === 'editing'
                            ?
                            <>{t('global.edit')}</>
                            :
                            <>{t('global.register')}</>
                    }
                    </Button>
                    {isAddingOrEditing === 'editing' && (
                        <Button variant="fourth" className="w-100 my-2 cursor" onClick={(e) => disableEnableBusinesses(e)}>
                            {state?.active ? t('global.disable') : t('global.enable')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Form>
    )
}