import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer"

export default function BillPDF({bill,typeBill}){

	const formatearFecha = (fechaISO) => {
		const fecha = new Date(fechaISO)
		const dia = fecha.getDate().toString().padStart(2, '0')
		const mes = (fecha.getMonth() + 1).toString().padStart(2, '0')
		const anio = fecha.getFullYear()
		return `${dia}/${mes}/${anio}`
	}

    const precioConIva = bill?.movements?.reduce((sum, movement) => sum + Number(movement.price || 0), 0)
    const taxRate = Number(bill.tax || 0)
    const baseImponible = precioConIva / (1 + taxRate / 100)
    const iva = precioConIva - baseImponible

	let styles = StyleSheet.create({
        pagina: {
            padding: 20
        },
        cabecera:{
            flexDirection: 'row',
        },
		general: {
			flexDirection: 'row',
			padding: 10
		},
        datosFactura: {
            border: '2px solid #000',
            marginTop: 5,
            padding: 1
        },
		textoNormal: {
            fontSize: 10,
            color: '#000',
            marginHorizontal: 3
        },
        textoPeq: {
            fontSize: 9,
            color: '#000',
            marginHorizontal: 3
        },
        datoNormal: {
            fontSize: 11,
            borderBottom: '1px solid #000',
            color: '#000',
            marginHorizontal: 3,
            paddingHorizontal: 3
        },
        table: {
            display: "table",
            width: "auto",
            border: "2px solid #000",
            borderRightWidth: 1,
            borderBottomWidth: 1,
            marginTop: 5
          },
          tableRow: {
            flexDirection: "row",
          },
          tableCol: {
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
          },
          tableHead: {
            margin: 5,
            fontSize: 10,
            fontWeight: "bold",
          },
          tableCell: {
            margin: 3,
            fontSize: 10,
          },
          cellTotal: {
            borderStyle: "solid",
            borderWidth: 2,
            borderTopWidth: 0,
          },
	})

	return (
		<Document>
            <Page size="A4" style={styles.pagina}>
                <View style={styles.cabecera}>
                    <View style={{ width: '33%'}}>
                        <Image src="/images/logo-facturas.jpg?v1.0" style={{ width: '100%'}}/>
                        <Text style={[styles.textoPeq, { textAlign: 'center'}]} >
                            RECICLAJE DE ACEITES Y GRASAS S.L.
                        </Text>
                        <Text style={[styles.textoPeq, { textAlign: 'center'}]} >
                            CIF: B-14664874
                        </Text>
                    </View>
                    <View style={{ width: '35%',paddingHorizontal: 10 }}>
                        <Text style={{ fontSize: 16, fontWeight: 'extrabold', marginTop: 20, textAlign: 'center' }}>
                            Nº Gestor GRU 357
                        </Text>
                        <Text style={{ fontSize: 14, fontWeight: 'extrabold', marginTop: 5, textAlign: 'center' }}>
                            Recogida, almacenamiento, transporte y reciclaje del aceite usado
                        </Text>
                    </View>
                    <View style={{ width: '32%', alignItems: 'flex-end', marginTop: 10 }}>
                        <Text style={styles.textoPeq}>
                            P.L. San Pancracio
                        </Text>
                        <Text style={styles.textoPeq}>
                            C/ Foret, nave 3, parc. 141
                        </Text>
                        <Text style={styles.textoPeq}>
                            Tlfs. 660 421 098 - 623 941 635
                        </Text>
                        <Text style={styles.textoPeq}>
                            14500 Puente Genil (Córdoba)
                        </Text>
                        <Text style={styles.textoPeq}>
                            info@reagracordoba.es
                        </Text>
                        <Text style={styles.textoPeq}>
                            oficina@reagracordoba.es
                        </Text>
                    </View>
                </View>
				<View style={styles.datosProveedor}>
					<View style={styles.general}>
                        <Text style={[styles.textoNormal, { width: '12%' }]}>CLIENTE</Text>
						<Text style={[styles.datoNormal, { width: '54%' }]}>{bill?.business?.companyName}</Text>
                        <Text style={[styles.textoNormal, { width: '10%' }]}>DNI/CIF</Text>
                        <Text style={[styles.datoNormal, { width: '20%' }]}>{bill?.business?.cif}</Text>
					</View>
					<View style={styles.general}>
                        <Text style={[styles.textoNormal, { width: '12%' }]}>DOMICILIO</Text>
						<Text style={[styles.datoNormal, { width: '86%' }]}>{bill?.business?.address}</Text>
					</View>
                    <View style={styles.general}>
                        <Text style={[styles.textoNormal, { width: '12%' }]}>POBLACIÓN</Text>
						<Text style={[styles.datoNormal, { width: '30%' }]}>{bill?.business?.location}</Text>
                        <Text style={[styles.textoNormal, { width: '5%' }]}>CP</Text>
                        <Text style={[styles.datoNormal, { width: '10%' }]}>{bill?.business?.cp}</Text>
                        <Text style={[styles.textoNormal, { width: '10%' }]}>PROVINCIA</Text>
                        <Text style={[styles.datoNormal, { width: '28%' }]}>{bill?.business?.province}</Text>
					</View>
                    <View style={styles.general}>
                        <Text style={[styles.textoNormal, { width: '12%' }]}>CÓDIGO LER</Text>
						<Text style={[styles.datoNormal, { width: '86%' }]}>200125</Text>
					</View>
				</View>
                <View style={styles.datosFactura}>
                    <View style={styles.general}>
                        <Text style={[styles.textoNormal, { width: '22%' }]}>
                            {typeBill === 'bill' ? 'Nº FACTURA' : 'FACTURA PROFORMA Nº'}
                        </Text>
                        <Text style={[styles.datoNormal, { width: '25%' }]}> {new Date(bill.date).getFullYear()} / {typeBill === 'bill' ? bill?.numBill : bill?.numProforma }</Text>
                        <Text style={[styles.textoNormal, { width: '15%' }]}>FECHA FACTURA</Text>
                        <Text style={[styles.datoNormal, { width: '32%' }]}>{formatearFecha(bill?.date)}</Text>
                    </View>
                </View>
                <View style={styles.table}>
                    {/* Encabezado de la tabla */}
                    <View style={styles.tableRow}>
                        <View style={[styles.tableCol, {width: '15%'}]}>
                            <Text style={styles.tableHead}>FECHA</Text>
                        </View>
                        <View style={[styles.tableCol, {width: '40%'}]}>
                            <Text style={styles.tableHead}>DESCRIPCIÓN</Text>
                        </View>
                        <View style={[styles.tableCol, {width: '15%'}]}>
                            <Text style={styles.tableHead}>TOTAL KG</Text>
                        </View>
                        <View style={[styles.tableCol, {width: '15%'}]}>
                            <Text style={styles.tableHead}>EURO/KG</Text>
                        </View>
                        <View style={[styles.tableCol, {width: '15%'}]}>
                            <Text style={styles.tableHead}>TOTAL</Text>
                        </View>
                    </View>
                    {/* Filas de datos */}
                    {bill.movements.map((movement, index) => (
                        <View style={styles.tableRow} key={index}>
                            <View style={[styles.tableCol, {width: '15%'}]}>
                                <Text style={styles.tableCell}>
                                    {movement.date && formatearFecha(movement?.date)}
                                </Text>
                            </View>
                            <View style={[styles.tableCol, {width: '40%'}]}>
                                <Text style={styles.tableCell}>{movement.description}</Text>
                            </View>
                            <View style={[styles.tableCol, {width: '15%'}]}>
                                <Text style={[styles.tableCell, {textAlign: 'right'}]}>{movement.quantity}</Text>
                            </View>
                            <View style={[styles.tableCol, {width: '15%'}]}>
                                <Text style={[styles.tableCell, {textAlign: 'right'}]}>{movement.document} €</Text>
                            </View>
                            <View style={[styles.tableCol, {width: '15%'}]}>
                                <Text style={[styles.tableCell, {textAlign: 'right'}]}>
                                    {((movement.price || 0) / (1 + (bill.tax || 0) / 100)).toFixed(2)} €
                                </Text>
                            </View>
                        </View>
                    ))}
                    {[...Array(20 - bill.movements.length)].map((_, index) => (
                        <View style={styles.tableRow} key={`empty-${index}`}>
                            <View style={[styles.tableCol, {width: '15%'}]}>
                                <Text style={styles.tableCell}> </Text>
                            </View>
                            <View style={[styles.tableCol, {width: '40%'}]}>
                                <Text style={styles.tableCell}> </Text>
                            </View>
                            <View style={[styles.tableCol, {width: '15%'}]}>
                                <Text style={styles.tableCell}> </Text>
                            </View>
                            <View style={[styles.tableCol, {width: '15%'}]}>
                                <Text style={styles.tableCell}> </Text>
                            </View>
                            <View style={[styles.tableCol, {width: '15%'}]}>
                                <Text style={styles.tableCell}> </Text>
                            </View>
                        </View>
                    ))}
                </View>
                <View style={styles.tableRow}>
                    <View style={{width: '84.7%'}}>
                        <Text style={[styles.tableCell, {textAlign: 'right'}]}>BASE IMPONIBLE</Text>
                    </View>
                    <View style={[styles.cellTotal, {width: '15.3%'}]}>
                        <Text style={[styles.tableCell, {textAlign: 'right'}]}>
                            {parseFloat(baseImponible).toFixed(2)} €
                        </Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={{width: '84.7%'}}>
                        <Text style={[styles.tableCell, {textAlign: 'right'}]}>IVA {bill.tax}%</Text>
                    </View>
                    <View style={[styles.cellTotal, {width: '15.3%'}]}>
                        <Text style={[styles.tableCell, {textAlign: 'right'}]}>
                            {parseFloat(iva).toFixed(2)} €
                        </Text>
                    </View>
                </View>
                <View style={styles.tableRow}>
                    <View style={{width: '84.7%'}}>
                        <Text style={[styles.tableCell, {textAlign: 'right'}]}>TOTAL</Text>
                    </View>
                    <View style={[styles.cellTotal, {width: '15.3%'}]}>
                        <Text style={[styles.tableCell, {textAlign: 'right'}]}>
                            {parseFloat(precioConIva).toFixed(2)} €
                        </Text>
                    </View>
                </View>
            </Page>
		</Document>
	)
}