export const businessesReducer = (state = {loading: true, resultbusiness: null, businesses: null, business: null } , action) => {
    switch (action.type) {
        case 'PROVIDER_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                businesses: action.payload.businesses
            }
        case 'PROVIDER_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                business: action.payload
            }
        case 'PROVIDER_ADD_SUCCESS':
            return {
                ...state,
                loading:false,
                businesses: [...state.businesses, action.payload.business],
                resultbusiness: action.payload.message
            }
        case 'PROVIDER_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                businesses: state.businesses.map(business => business._id === action.payload.business._id ? action.payload.business : business),
                resultbusiness: action.payload.message
            }
        case 'DOCUMENT_ADD_SUCCESS':
            return {
                ...state,
                loading:false,
                businesses: state.businesses.map(business => business._id === action.payload.business._id ? action.payload.business : business),
                resultbusiness: action.payload.message
            }
        case 'DOCUMENT_DELETE_SUCCESS':
            return {
                ...state,
                loading:false,
                businesses: state.businesses.map(business => business._id === action.payload.business._id ? action.payload.business : business),
                resultbusiness: action.payload.message
            }
        case 'PROVIDER_ENABLEDISABLE_SUCCESS':
            return {
                ...state,
                loading:false,
                business: action.payload.business,
                businesses: state.businesses.map(business => business._id === action.payload.business._id ? action.payload.business : business),
                resultbusiness: action.payload.message
            }
        case 'PROVIDER_RESET_RESULT':
            return {...state,
                resultbusiness: null
            }
        case 'PROVIDER_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'PROVIDER_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

